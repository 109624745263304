








































































































































































































































































































































































































































































































































import ModalWrapper from '@components/ModalWrapper.vue'
import AstCheckbox from '@components/Checkbox.vue'
import BirthdateAge from '@components/BirthdateAgeInputs.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import EmailSelect from '@components/EmailSelect.vue'
import { userData } from '@services/userData'
import scheduleData, { ScheduleStatus, scheduleStatusIdDesc } from '@services/scheduleData'
import { setLoading } from '@components/Loading.vue'
import DateTimePicker from '@components/DateTimePicker.vue'
import api from '@services/api'
import store from '@store'
import { addNotification } from '@services/notificationService'
import { openScheduleItemReport } from './ScheduleItemReport.vue'
import ItemStatusMixin from '@/schedule/mixins/ItemStatus'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'
import { openPromptDlg } from '@dialogs/TextPromptDlg.vue'

const ScheduleItemDlg = {
	name: 'ScheduleItemDlg',
	mixins: [ItemStatusMixin],
	components: {
		AstCheckbox,
		BirthdateAge,
		ModalWrapper,
		DlgHeader,
		EmailSelect,
		DateTimePicker,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isLoading: true,
			isSaving: false,
			config: scheduleData.config,
			isAdmin: false,
			requestedDateTime: null,
			modalities: [],
			scheduleModalities: [],
			scheduleDateTimeConflict: false,
			scheduleDateTimeConflict2: false,
			scheduleDateTimeConflict3: false,
			examTypes: [],
			consultants: [],
			users: [],
			speciesList: [],
			breedList: [],
			genderList: ['Male', 'Male Castrated', 'Female', 'Female Spayed'],
			options: {
				isPetRays: false,
				petRaysExamTypeList: [],
				residents: [],
				radiologists: [],
				PriorityOptions: [],
			},
		}
	},
	computed: {
		canStatus() {
			if (this.item.hasMppsAssociation) return false
			return this.isAdmin || userData.permissions.schedulingStatus
		},
		canEditBilled() {
			return this.isAdmin || userData.permissions.schedulingStatus
		},
		isMppsBreakAllowed() {
			let item: IScheduleItem = this.item
			return item.hasMppsAssociation && this.isAdmin && item.scheduleStatusId < ScheduleStatus.ImagingComplete
		},
		canStartImaging() {
			if (this.item.scheduleModalityId && this.canStatus && this.item.scheduleStatusId === ScheduleStatus.Scheduled)
				return true
			if (
				this.canStatus &&
				this.item.scheduleStatusId === ScheduleStatus.ImagingComplete &&
				this.item.studyId &&
				!this.item.consultantId
			)
				return true
			return false
		},
		canStopImaging() {
			const isImaging = [
				ScheduleStatus.Started,
				ScheduleStatus.ImagesAvailable,
				ScheduleStatus.ImagesPendingReview,
			].includes(this.item.scheduleStatusId)
			if (this.canStatus && isImaging) return true
			if (
				this.canStatus &&
				this.item.scheduleStatusId === ScheduleStatus.ImagingComplete &&
				!this.item.studyId &&
				!this.item.consultantId
			)
				return true
			return false
		},
		canStartReport() {
			const isImagingComplete = this.item.scheduleStatusId > ScheduleStatus.ImagesPendingReview
			return this.item.id && !isImagingComplete && this.item.consultantId && this.$store.getters.isConsultantUser
		},
		canCompleteImaging() {
			const isImaging = [
				ScheduleStatus.Started,
				ScheduleStatus.ImagesAvailable,
				ScheduleStatus.ImagesPendingReview,
			].includes(this.item.scheduleStatusId)
			return this.canStatus && isImaging && this.item.studyId
		},
		modality() {
			if (!this.item.scheduleModalityId || !this.scheduleModalities.length) return null
			return this.scheduleModalities.find(m => m.id === this.item.scheduleModalityId)
		},
		currentStatus() {
			return scheduleStatusIdDesc(this.item.scheduleStatusId)
		},
		hasScheduleConflict() {
			return this.scheduleDateTimeConflict || this.scheduleDateTimeConflict2 || this.scheduleDateTimeConflict3
		},
		patientNameLabel() {
			return (this.modality && this.modality.patientNameLabel) || 'Patient Name'
		},
		patientIdLabel() {
			return (this.modality && this.modality.patientIdLabel) || 'Patient ID'
		},
		responsiblePersonLabel() {
			return (this.modality && this.modality.responsiblePersonLabel) || 'Owner Name'
		},
		accessionNumberLabel() {
			return (this.modality && this.modality.accessionNumberLabel) || 'Accession Number'
		},
		readonly() {
			if (!this.item.id) return false
			return !this.isAdmin || this.item.scheduleStatusId > ScheduleStatus.ImagesPendingReview || this.isMppsBreakAllowed
		},
		emailRecipients: {
			get() {
				if (!this.item.emailRecipients) return []
				return this.item.emailRecipients.split(',').map(e => e.trim())
			},
			set(recipients) {
				this.item.emailRecipients = recipients.join(',')
			},
		},
	},
	watch: {
		'item.patientSpeciesDescription': {
			handler(species) {
				this.getBreedList()
			},
			immediate: false,
		},
		'item.consultantId'() {
			this.getBreedList()
			this.getOptions()
		},
		'item.modalityId'() {
			this.getBreedList()
			this.getOptions()
		},
	},
	async created() {
		this.isLoading = true
		this.isAdmin = userData.permissions.schedulingAdmin
		this.modalities = this.$store.state.static.modalities
		this.consultants = this.$store.state.static.consultants
		try {
			const promises = [
				api.patient.getSpecies(this.item.consultantId).then(r => {
					this.speciesList = r
					this.getBreedList()
				}),

				this.getOptions(),
				userData.getAllUsers().then(r => (this.users = r)),
				scheduleData.getModalities().then(r => (this.scheduleModalities = r)),
			]
			await Promise.all(promises)
		} finally {
			this.isLoading = false
		}
	},
	methods: {
		async breakMPPS() {
			let msg = `This will break the MPPS (Modality Performed Procedure Step) association between Keystone and the modality. The MPPS association should only be broken if the study cannot be completed on the modality.  The proper way to break the association is the cancel/discontinue the study on the modality. Once the MPPS association is broken, you will need to start the study from the beginning.  Are you sure you want to do this?`
			let r = await showConfirm(msg)
			if (r) {
				scheduleData.breakMPPS(this.item)
			}
		},
		async prefillPatient() {
			const study = await openStudiesDlg()
			if (!study) return
			this.item.patientId = study.patientId
			this.item.patientName = study.patientName
			this.item.responsiblePerson = study.ownerName
			this.item.patientSpeciesDescription = study.speciesName
			this.item.patientBreedDescription = study.breedName
			this.item.patientSex = study.patientGender
		},
		async onScheduleChange(modalityNumber: string = '') {
			const scheduleModalityId = this.item['scheduleModalityId' + modalityNumber]
			if (!scheduleModalityId) this.item['scheduleDateTime' + modalityNumber] = null
			if (!this.item['scheduleDateTime' + modalityNumber]) return
			const scheduleDateTime = new Date(this.item['scheduleDateTime' + modalityNumber])
			this['scheduleDateTimeConflict' + modalityNumber] = false
			const modality: IScheduleModality = this.scheduleModalities.find(m => m.id === scheduleModalityId)
			if (!modality) return
			const beginDate = new Date(scheduleDateTime.getTime() - (modality.defaultExamLength - 1) * 60000)
			const endDate = new Date(scheduleDateTime.getTime() + modality.defaultExamLength * 60000)
			const hasConflict = await scheduleData.checkForScheduleConflict(
				scheduleModalityId,
				beginDate,
				endDate,
				this.item.id
			)
			this['scheduleDateTimeConflict' + modalityNumber] = hasConflict
		},
		startReport() {
			openScheduleItemReport(this.item)
		},
		stopImaging() {
			let p = scheduleData.updateItemStatus(this.item, ScheduleStatus.Scheduled, this.item.isBilled)
			setLoading('Saving ...', p)
		},
		startImaging() {
			let p = scheduleData.updateItemStatus(this.item, ScheduleStatus.Started, this.item.isBilled)
			setLoading('Saving ...', p)
		},
		completeImaging() {
			let p = scheduleData.updateItemStatus(this.item, ScheduleStatus.ImagingComplete, this.item.isBilled)
			setLoading('Saving ...', p)
		},
		async getOptions() {
			this.options = await scheduleData.getTelemedicineOptions(this.item.consultantId, this.item.modalityId)
		},
		isSameModality(modalityIdA, modalityIdB) {
			const cr = this.$store.state.static.modalities.find(m => m.name === 'CR').id
			const dx = this.$store.state.static.modalities.find(m => m.name === 'DX').id
			// treat CR and DX as same modality
			if ([cr, dx].includes(modalityIdA) && [cr, dx].includes(modalityIdB)) {
				return true
			}
			return modalityIdA === modalityIdB
		},
		getFilteredScheduleModalities(modalityId) {
			return this.scheduleModalities.filter(m => this.isSameModality(m.modalityId, modalityId))
		},
		async getBreedList() {
			let s = this.speciesList.find(s => s.name === this.item.patientSpeciesDescription)
			if (!s) {
				this.breedList = []
				return
			}

			const list = await api.patient.getBreeds(s.id)
			this.breedList = [...new Set(list.map(breed => breed.name))]
		},
		async save() {
			if (this.item.id && !this.isAdmin && !this.canStatus && !this.canEditBilled) return
			this.isSaving = true
			try {
				let p: Promise<any> = null
				if (this.item.id && !this.readonly) {
					if (!this.item.scheduleDateTime) this.scheduleStatusId = ScheduleStatus.Queued
					p = scheduleData.updateItem(this.item)
				} else if (!this.item.id && !this.readonly) {
					p = scheduleData.createItem(this.item)
				} else {
					p = scheduleData.updateItemStatus(this.item, this.item.scheduleStatusId, this.item.isBilled)
				}
				await p
				if (!this.item.id) {
					addNotification(
						'Your exam has been added to the schedule. View the Schedule to monitor its progress.',
						'success'
					)
				}
				dlg.close(this, true, true)
			} finally {
				this.isSaving = false
			}
		},
		async deleteItem() {
			const cancelMessage = await openPromptDlg({
				buttonLabel: 'OK',
				prompt: 'Please provide a reason for deleting this item.',
				requireInput: true,
				type: 'textarea',
			})
			if (!cancelMessage) return
			this.item.scheduleStatusId = ScheduleStatus.CancelQueued
			this.item.deleted = true
			this.item.cancelMessage = cancelMessage
			this.item.cancelUserId = userData.claims.userId
			this.item.cancelDateTime = new Date()
			this.isLoading = true
			try {
				await scheduleData.updateItem(this.item)
				dlg.close(this, true, true)
			} finally {
				this.isLoading = false
			}
		},
		close() {
			dlg.close(this, true, false)
		},
		confirm() {
			dlg.close(this, true, true)
		},
	},
}

export default ScheduleItemDlg

export function openScheduleItemDlg(itemToEdit: IScheduleItem, study?: IStudy, patient?: IPatient, values?: any) {
	if (!itemToEdit) {
		itemToEdit = {
			id: null,
			scheduleStatusId: null,
			modalityId: null,
			modalityId2: null,
			modalityId3: null,
			scheduleDateTime: null,
			scheduleDateTime2: null,
			scheduleDateTime3: null,
			scheduleModalityId: null,
			scheduleModalityId2: null,
			scheduleModalityId3: null,

			status: null,
			studyId: null,
			caseId: null,
			consultantId: scheduleData.config.consultantId || null,
			consultantReportId: null,
			hasMppsAssociation: false,

			requestedDateTime: new Date(),
			patientName: null,
			patientId: null,
			responsiblePerson: null,
			accessionNumber: null,
			requestingPhysician: null,
			referringPhysiciansName: null,
			admittingDiagnosisDescription: null,

			patientBirthDate: study ? study.patientBirthDate : null,
			patientSex: study ? study.patientGender : null,
			patientWeight: null,
			patientSexNeutered: null,
			patientSpeciesDescription: null,
			patientBreedDescription: null,
			patientComments: null,
			specialNeeds: null,

			requestedProcedureStepDescription: null,
			requestedProcedureId: null,
			requestedProcedureDescription: null,
			requestedProcedurePriority: null,
			createdDateTime: null,
			reportHistory: null,
			teleradiologyUserId: userData.claims.userId,
			visitId: null,
			currentPatientLocation: null,
			referringPhysicianTelephoneNumbers: null,
			preMedication: null,
			medicalAlerts: null,
			emailRecipients: null,
			petRaysExamType: null,
			petRaysSonographerFindings: null,
			deleted: false,
			cancelMessage: '',
			isBilled: false,
			reportPriority: null,
			requestedRadiologistConsultantId: null,
			requestedResidentConsultantId: null,
			scheduleAssociationKey: null,
		}

		if (study) {
			itemToEdit.studyId = study.studyId
			itemToEdit.referringPhysiciansName = study.referringPhysiciansName

			let found = store.state.static.modalities.find(m => m.name === study.modality)
			if (found) itemToEdit.modalityId = found.id

			itemToEdit.patientName = study.patientName
			itemToEdit.patientId = study.patientId
			itemToEdit.responsiblePerson = study.ownerName
			itemToEdit.patientSpeciesDescription = study.speciesName
			itemToEdit.patientBreedDescription = study.breedName
			itemToEdit.patientSex = study.patientGender
			itemToEdit.patientBirthDate = study.patientBirthDate
		} else if (patient) {
			itemToEdit.patientId = patient.id
			itemToEdit.patientName = patient.name
			itemToEdit.patientBirthDate = patient.birthdate
			itemToEdit.patientBreedDescription = patient.breed
			itemToEdit.patientSpeciesDescription = patient.species
			itemToEdit.patientSex = patient.gender
		}
		if (values) itemToEdit = { ...itemToEdit, ...values }
	} else {
		itemToEdit = JSON.parse(JSON.stringify(itemToEdit))
	}
	return dlg.open(ScheduleItemDlg, { item: itemToEdit })
}
