import { API, paramsSerializer, serializeDate } from '@services/api'

export enum ScheduleStatus {
	/// <summary>
	/// The status is unknown
	/// </summary>
	Unknown = 0,
	/// <summary>
	/// Item is in the queue and waiting to be scheduled
	/// </summary>
	Queued = 1,
	/// <summary>
	/// Item is scheduled but imaging has not started
	/// </summary>
	Scheduled = 2,
	/// <summary>
	/// Imaging has started
	/// </summary>
	Started = 3,
	/// <summary>
	/// Images have been received for the study
	/// </summary>
	ImagesAvailable = 4,
	/// <summary>
	/// Images have been received are awaiting review/approval
	/// </summary>
	ImagesPendingReview = 5,
	/// <summary>
	/// All images have been received
	/// </summary>
	ImagingComplete = 6,
	/// <summary>
	/// A teleradiology report has been started
	/// </summary>
	ReportInProgress = 7,
	/// <summary>
	/// The teleradiology report has a draft that is waiting for approval
	/// </summary>
	ReportPendingApproval = 8,
	/// <summary>
	/// The teleradiology report is finished but waiting for "rounds" review
	/// </summary>
	ReportPendingRounds = 9,
	/// <summary>
	/// The teleradiology report is complete
	/// </summary>
	ReportComplete = 10,
	/// <summary>
	/// The item has been queued at the annex and needs to be forwarded to the account that will perform imaging.
	/// </summary>
	AnnexQueued = 11,
	/// <summary>
	/// The item has been queued for cancellation.  Annex will finish the cancellation and send email notifications.
	/// </summary>
	CancelQueued = 12,
	/// <summary>
	/// The item has been fully cancelled and cannot be accessed anymore.
	/// </summary>
	Cancelled = 13,
}

export function scheduleStatusIdDesc(status: ScheduleStatus): string {
	switch (status) {
		case ScheduleStatus.Started:
			return 'Imaging started'
		case ScheduleStatus.ImagesAvailable:
			return 'Images have been received'
		case ScheduleStatus.ImagesPendingReview:
			return 'Imaging pending review'
		case ScheduleStatus.ImagingComplete:
			return 'Imaging complete'
		case ScheduleStatus.ReportInProgress:
			return 'Report started'
		case ScheduleStatus.ReportPendingApproval:
			return 'Report pending approval'
		case ScheduleStatus.ReportPendingRounds:
			return 'Report pending rounds approval'
		case ScheduleStatus.ReportComplete:
			return 'Report complete'
		case ScheduleStatus.CancelQueued:
			return 'Deleting ...'
		default:
			return ScheduleStatus[status]
	}
}

class ScheduleData {
	config: IScheduleConfiguration = null

	createItem(item: IScheduleItem) {
		let copy = { ...item }
		copy.requestedDateTime = serializeDate(item.requestedDateTime, false)
		copy.scheduleDateTime = serializeDate(item.scheduleDateTime, false)
		copy.scheduleDateTime2 = serializeDate(item.scheduleDateTime2, false)
		copy.scheduleDateTime3 = serializeDate(item.scheduleDateTime3, false)
		return API.post('/schedule/create-item', copy, { paramsSerializer })
	}

	updateItemStatus(item: IScheduleItem, status: ScheduleStatus, billed: boolean) {
		return API.put('/schedule/update-item-status', null, {
			params: {
				id: item.id,
				billed,
				status,
			},
		}).then(r => {
			item.scheduleStatusId = status
		})
	}

	updateItem(item: IScheduleItem) {
		let copy = { ...item }
		copy.requestedDateTime = serializeDate(item.requestedDateTime, false)
		copy.scheduleDateTime = serializeDate(item.scheduleDateTime, false)
		copy.scheduleDateTime2 = serializeDate(item.scheduleDateTime2, false)
		copy.scheduleDateTime3 = serializeDate(item.scheduleDateTime3, false)
		return API.put('/schedule/update-item', copy)
	}

	scheduleItem(item: IScheduleItem) {
		let form = {
			itemId: item.id,
			scheduleDateTime: serializeDate(item.scheduleDateTime, false),
			scheduleModalityId: item.scheduleModalityId,
		}

		return API.put('/schedule/schedule-item', form)
	}

	getItems(modalityId: string, beginDate: Date, endDate: Date, queued: boolean): Promise<IScheduleItem[]> {
		return API.get('/schedule/items', {
			params: {
				modalityId,
				beginDate: serializeDate(beginDate, false),
				endDate: serializeDate(endDate, false),
				queued,
			},
		}).then(r => r.data)
	}

	async checkForScheduleConflict(
		modalityId: string,
		beginDate: Date,
		endDate: Date,
		itemId?: string
	): Promise<boolean> {
		const items: IScheduleItem[] = await API.get('/schedule/items', {
			params: {
				modalityId,
				beginDate,
				endDate,
				queued: false,
			},
		}).then(r => r.data)
		if (itemId) return items.some(i => i.id !== itemId)
		return !!items.length
	}

	saveModality(modality: IScheduleModality) {
		return API.post('/schedule/save-modality', modality).then(r => r.data)
	}

	saveModalities(modalities: IScheduleModality[]) {
		return API.post('/schedule/save-modalities', modalities).then(r => r.data)
	}

	getModalities(): Promise<IScheduleModality[]> {
		return API.get('/schedule/modalities').then(r => r.data)
	}

	getDicomTags() {
		return API.get('/schedule/dicom-tags').then(r => r.data)
	}

	async getConfiguration(): Promise<IScheduleConfiguration> {
		this.config = await API.get('/schedule/configuration').then(r => r.data)
		return this.config
	}

	saveConfiguration(configuration: IScheduleConfiguration) {
		return API.post('/schedule/configuration', configuration).then(() => {
			this.config = configuration
		})
	}

	getTelemedicineOptions(consultantId?: string, modalityId?: number): Promise<IScheduleItemOptions> {
		if (consultantId && consultantId === '00000000-0000-0000-0000-000000000000') {
			consultantId = null
		}
		return API.get('/schedule/telemedicine-options', {
			params: {
				consultantId,
				modalityId,
			},
		}).then(r => r.data)
	}

	startResponseReport(scheduleItemId: string, consultantId: string): Promise<IConsultantReport> {
		let config = {
			params: {
				consultantId,
			},
		}

		return API.get(`/schedule/start-response-report/${scheduleItemId}`, config).then(r => r.data)
	}

	saveResponseReport(scheduleItemId: string, consultantId: string, form: IConsultantResponseForm) {
		let config = {
			params: {
				consultantId,
			},
		}

		return API.post(`/schedule/save-response-fields/${scheduleItemId}`, form, config)
	}

	breakMPPS(item: IScheduleItem) {
		return API.get(`/schedule/break-mpps/${item.id}`).then(r => {
			item.hasMppsAssociation = false
			item.mppsAssociationDeleted = true
		})
	}
}

export const scheduleData = new ScheduleData()
export default scheduleData
// @ts-ignore
window.scheduleData = scheduleData
scheduleData.getConfiguration()
