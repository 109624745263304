<template>
	<div>
		<ast-modal-wrapper @close="close">
			<div class="dialog residents">
				<dlg-header title="Select a resident to review their response." @close="close" />
				<div class="dialog-body">
					<data-table
						:columns="columns"
						:rows="sortedResidentResponses"
						:sort="listSort"
						:sticky-headers="true"
						@sort-selected="handleSortSelected"
						@row-open="selectResident"
					>
						<template #row="{ row }">
							<td :class="{ 'no-response': !row.status }">
								<svg-icon v-if="row.status" class="hover-icon" icon="pencil" />
							</td>
							<td :class="{ 'no-response': !row.status }" data-test="resident-name">
								{{ row.consultantName }}
							</td>
							<td :class="{ 'no-response': !row.status }" style="font-size: 0.8em;">
								<b v-if="row.status" class="is-info">
									{{ row.editDateTime | localizeDate }}
								</b>
								<em v-else>
									No response
								</em>
							</td>
							<td :class="{ 'no-response': !row.status }" style="font-size: 0.8em;">
								<b v-if="row.status === 2" class="is-success">
									<svg-icon icon="check-circle" fixed inline />
									{{ row.comment.date | localizeDate }}
								</b>
								<em v-else> Not reviewed <span v-if="row.comment && row.comment.message">(Draft saved)</span> </em>
							</td>
							<td :class="{ 'no-response': !row.status }">
								<button
									v-if="!isReportComplete && isOwner && row.status === 2"
									style="font-size: 0.8em;"
									class="btn btn-info"
									data-test="copy-resident-response-button"
									@click.stop="copyToOfficalReport(row)"
								>
									<svg-icon icon="copy" fixed />
									Copy to official report
								</button>
							</td>
							<td :class="{ 'no-response': !row.status }" style="width: auto;"> </td>
						</template>
					</data-table>
				</div>
				<div class="footer right">
					<button class="btn btn-default" data-test="close-resident-dlg-button" @click="close()">Cancel</button>
				</div>
			</div>
		</ast-modal-wrapper>
	</div>
</template>

<script>
import { dlg } from '@utils/dlgUtils'
import AstModalWrapper from '@components/ModalWrapper'
import DlgHeader from '@components/DlgHeader.vue'
import DataTable from '@components/DataTable.vue'

const Residents = {
	name: 'Residents',
	components: {
		AstModalWrapper,
		DlgHeader,
		DataTable,
	},
	props: {
		residentResponses: {
			type: Array,
			required: true,
		},
		isReportComplete: {
			type: Boolean,
			required: true,
		},
		isOwner: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			filter: {
				order: {
					by: 'responseDate',
					isAscending: false,
				},
			},
			columns: [
				{}, // pencil icon
				{
					name: 'Name',
					sortName: 'consultantName',
					columnName: 'consultantName',
					isSortable: true,
				},
				{
					name: 'Response Date',
					sortName: 'responseDate',
					columnName: 'responseDate',
					isSortable: true,
				},
				{
					name: 'Review Date',
					sortName: 'reviewDate',
					columnName: 'reviewDate',
					isSortable: true,
				},
				{}, // copy button
				{}, // extra space
			],
		}
	},
	computed: {
		listSort() {
			return {
				orderBy: this.filter.order.by,
				isAscending: this.filter.order.isAscending,
				isSorted: true,
			}
		},
		sortedResidentResponses() {
			return this.residentResponses
				.map(r => ({
					...r,
					responseDate: r.status > 0 ? new Date(r.editDateTime).getTime() : null,
					reviewDate: r.comment && new Date(r.comment.date).getTime(),
				}))
				.sort((a, b) => {
					let sa = a[this.filter.order.by] || ''
					let sb = b[this.filter.order.by] || ''
					return sa.toString().localeCompare(sb.toString()) * (this.filter.order.isAscending ? 1 : -1)
				})
		},
	},
	methods: {
		copyToOfficalReport(residentResponse) {
			if (residentResponse.status === 0) return
			dlg.close(this, true, { responseToApply: residentResponse })
		},
		handleSortSelected(evt) {
			this.filter.order.by = evt.name
			this.filter.order.isAscending = evt.isAscending
		},
		selectResident(residentResponse) {
			if (residentResponse.status === 0) return
			dlg.close(this, true, { responseToView: residentResponse })
		},
		close() {
			dlg.close(this, true, null)
		},
	},
}

export default Residents
export function openResidents(residentResponses, isReportComplete, isOwner) {
	return dlg.open(Residents, { residentResponses, isReportComplete, isOwner })
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.residents,
.loading {
	background: var(--secondary-bg);
}
.residents.dialog {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	padding: 0;
	height: -webkit-fill-available;
	@media (min-width: $mqSmall) {
		width: auto;
		min-width: 600px;
		height: 70vh;
	}
	th,
	td {
		width: 0.1%;
		white-space: nowrap;
	}
	td.no-response {
		cursor: initial;
	}
	.no-results {
		padding: 16px;
	}
	.hover-icon {
		opacity: 0;
	}
	tr:hover .hover-icon {
		opacity: 1;
	}
	.dialog-body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		background: var(--primary-bg);
		border: 1px solid var(--secondary-border);
		border-left: 0;
		border-right: 0;
		overflow: auto;
		height: 100%;
		padding: 0;
	}
	.footer {
		padding: 0 16px 16px 16px;
	}
	.check-all {
		flex-grow: 1;
		margin-bottom: 0;
		font-size: 0.9em;
	}
	.loading {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		opacity: 0.6;
		.icon {
			font-size: 4em;
		}
		p {
			margin-top: 16px;
			font-size: 1.2em;
		}
	}
}
</style>
