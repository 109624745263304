import Vue from 'vue'
// https://www.the-art-of-web.com/javascript/search-highlight/

export function Hilitor(targetNode: HTMLElement, tag) {
	let hiliteTag = tag || 'MARK'
	let skipTags = new RegExp('^(?:' + hiliteTag + '|SCRIPT|FORM)$')
	let colors = ['#ff6', '#a0ffff', '#9f9', '#f99', '#f6f']
	let wordColor = []
	let colorIdx = 0
	let matchRegExp: RegExp = null
	// characters to strip from start and end of the input string
	let endRegExp = new RegExp('^[^\\w]+|[^\\w]+$', 'g')
	// characters used to break up the input string into words
	let breakRegExp = new RegExp("[^\\w'-]+", 'g')

	this.setRegex = function(input) {
		input = input.replace(endRegExp, '')
		input = input.replace(breakRegExp, '|')
		input = input.replace(/^\||\|$/g, '')
		if (input) {
			let re = '(' + input + ')'
			matchRegExp = new RegExp(re, 'i')
			return matchRegExp
		}
		return false
	}

	// recursively apply word highlighting
	this.hiliteWords = function(node: Node) {
		if (node === undefined || !node) return
		if (!matchRegExp) return
		if (skipTags.test(node.nodeName)) return

		if (node.hasChildNodes()) {
			for (let i = 0; i < node.childNodes.length; i++) this.hiliteWords(node.childNodes[i])
		}
		if (node.nodeType === 3) {
			// NODE_TEXT
			let textNode: Text = <Text>node
			let regs = node.nodeValue && matchRegExp.exec(node.nodeValue)
			if (regs) {
				if (!wordColor[regs[0].toLowerCase()]) {
					wordColor[regs[0].toLowerCase()] = colors[colorIdx++ % colors.length]
				}

				let match = document.createElement(hiliteTag)
				match.appendChild(document.createTextNode(regs[0]))
				match.style.backgroundColor = wordColor[regs[0].toLowerCase()]
				match.style.color = '#000'

				let after = textNode.splitText(regs.index)
				after.nodeValue = after.nodeValue.substring(regs[0].length)
				node.parentNode.insertBefore(match, after)
			}
		}
	}

	// remove highlighting
	this.remove = function() {
		let arr = document.getElementsByTagName(hiliteTag)
		let el = null

		while (arr.length && (el = arr[0])) {
			let parent = el.parentNode
			parent.replaceChild(el.firstChild, el)
			parent.normalize()
		}
	}

	// start highlighting at target node
	this.apply = function(input) {
		this.remove()
		if (input === undefined || !(input = input.replace(/(^\s+|\s+$)/g, ''))) {
			return
		}
		if (this.setRegex(input)) {
			this.hiliteWords(targetNode)
		}
		return matchRegExp
	}
}

export const highlightKeywords = function(keywords: string[], rootSelector: string) {
	Vue.nextTick(() => {
		let root: HTMLElement = document.querySelector(rootSelector)
		if (keywords && keywords.length > 0 && root) {
			let hl = new Hilitor(root, undefined)
			hl.apply(keywords.join(' '))
		}
	})
}
