<template>
	<div id="save-report" class="">
		<span v-if="saving" key="saving">Saving &hellip;</span>
		<span v-else key="saved">
			<svg-icon icon="check" fixed class="is-success" />
			Saved
		</span>
	</div>
</template>

<script>
export default {
	name: 'SaveReport',
	props: {
		saving: {
			type: Boolean,
			required: true,
		},
	},
}
</script>

<style lang="scss">
#save-report {
	display: flex;
	align-items: center;
	margin: 0 12px;
	font-size: 0.9em;
}
</style>
