





















import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import scheduleData from '@services/scheduleData'
import { ReportDetail } from '@/reporting/classes'
import ResponseSelector from '@reporting/primitives/ResponseSelector.vue'

const ScheduleItemReport = {
	name: 'ScheduleItemReport',
	components: {
		DlgHeader,
		ModalWrapper,
		ResponseSelector,
	},
	props: ['item'],
	data() {
		return {
			report: null,
		}
	},
	computed: {
		template() {
			return this.report && this.report.response
		},
	},
	async created() {
		let item: IScheduleItem = this.item
		let report = await scheduleData.startResponseReport(item.id, item.consultantId)
		this.report = await ReportDetail.loadFromScheduleItemReport(report)
	},
	methods: {
		async save() {
			let report: ReportDetail = this.report
			let item: IScheduleItem = this.item
			await scheduleData.saveResponseReport(item.id, item.consultantId, report.getSaveResponseForm())
			this.close()
		},
		close() {
			dlg.close(this)
		},
	},
}

export default ScheduleItemReport

export function openScheduleItemReport(item: IScheduleItem) {
	return dlg.open(ScheduleItemReport, { item })
}
