<template>
	<div v-if="tabs_.length" ref="wrapper" class="tabs-wrapper" @wheel="onWheel">
		<div class="tabs" :class="{ 'is-accent': accent, 'is-low-contrast': lowContrast }" :style="style">
			<div
				v-for="tab in tabs_"
				:key="tab.id"
				:data-id="tab.id"
				class="tab-item"
				:class="{ 'is-selected': tab === selectedTab, 'is-closable': tab.closable }"
				draggable
				@click="selectTab(tab)"
				@dragstart="onDragStart($event, tab)"
				@dragenter.prevent
				@dragover="onDragEnter($event, tab)"
				@dragleave="onDragLeave($event, tab)"
				@drop="onDrop($event, tab)"
			>
				<slot v-bind="tab">
					{{ tab.label }}
				</slot>
				<div class="close">
					<svg-icon v-if="tab.closable" icon="close" @click.native.stop="closeTab(tab)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		tabs: Array,
		value: null,
		accent: Boolean,
		lowContrast: Boolean,
		canReorder: Boolean,
		offset: { type: String, default: '10px' },
	},
	data() {
		return {
			tabs_: this.normalizeTabs(this.tabs),
			value_: this.value,
			draggedOverEl: null,
		}
	},
	computed: {
		style() {
			const style = {}
			if (this.mq.small) style.paddingLeft = this.offset
			return style
		},
		selectedTab() {
			return this.tabs_.find(t => t.value === this.value_)
		},
	},
	watch: {
		tabs(val) {
			this.tabs_ = this.normalizeTabs(val)
		},
		tabs_(val) {
			this.$emit('update:tabs', val)
		},
		value(val) {
			this.value_ = val
		},
		value_(val) {
			this.$emit('input', val)
		},
		selectedTab: {
			immediate: true,
			handler(tab) {
				if (tab) {
					this.$nextTick(() => this.focusTab(tab.id))
				}
			},
		},
	},
	methods: {
		normalizeTabs(tabs) {
			return (tabs || []).map((tab, i) => {
				const result = {}
				if (typeof tab === 'string') {
					result.id = i
					result.label = tab
					result.value = tab
				} else {
					Object.assign(
						result,
						{
							id: i,
							value: tab.id,
						},
						tab
					)
				}
				result.closable = result.closable || !!result.onClose
				return result
			})
		},
		selectTab(tab) {
			if (tab.onSelect) tab.onSelect(tab)
			this.value_ = tab.value
		},
		moveTab(fromIndex, toIndex) {
			if (toIndex >= this.tabs_.length) return
			this.tabs_.splice(toIndex, 0, this.tabs_.splice(fromIndex, 1)[0])
		},
		closeTab(tab) {
			if (tab.onClose) tab.onClose()
			const index = this.tabs_.findIndex(t => t.id === tab.id)
			this.tabs_.splice(index, 1)
		},
		focusTab(tabId) {
			const el = this.$el.querySelector(`.tab-item.is-selected[data-id="${tabId}"]`)
			if (el) {
				el.scrollIntoView()
			}
		},
		findAncestor(el, cls) {
			while (el && !el.classList.contains(cls) && (el = el.parentElement));
			return el
		},
		findTabItem(el) {
			return this.findAncestor(el, 'tab-item')
		},
		onDragStart(event, tab) {
			if (!this.canReorder) return
			this.value_ = tab.value
			event.dataTransfer.dropEffect = 'move'
			event.dataTransfer.effectAllowed = 'move'
			event.dataTransfer.setData('itemId', tab.id)
		},
		onDragEnter(event, tab) {
			if (!this.canReorder || tab.value === this.value_) return
			event.preventDefault()
			// Get tab item ancestor since event target could be slot content
			const el = this.findTabItem(event.target)
			if (!el) return
			this.draggedOverEl = el
			el.classList.add('is-dragging-over')
		},
		onDragLeave(event, tab) {
			if (!this.canReorder || tab.value === this.value_) return
			const el = this.findTabItem(event.target)
			if (!el) return
			this.draggedOverEl = null
			// Clear drag class on timeout to prevent flickering
			setTimeout(() => {
				if (!this.draggedOverEl || this.draggedOverEl !== el) {
					el.classList.remove('is-dragging-over')
				}
			}, 80)
		},
		onDrop(event, tab) {
			if (!this.canReorder) return
			// Remove class from dragged over element
			const el = this.findTabItem(event.target)
			if (el) el.classList.remove('is-dragging-over')
			// Move tab to new position
			const itemId = event.dataTransfer.getData('itemId')
			const fromIndex = this.tabs_.findIndex(t => t.id === itemId)
			const toIndex = this.tabs_.findIndex(t => t.id === tab.id)
			this.moveTab(fromIndex, toIndex)
		},
		onWheel(event) {
			const el = this.$refs.wrapper
			if (event.deltaY > 0) {
				el.scrollLeft += 50
			} else {
				el.scrollLeft -= 50
			}
			event.preventDefault()
		},
	},
}
</script>
