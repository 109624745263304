import { ScheduleStatus } from '@services/scheduleData'
export default {
	methods: {
		getItemClasses(item: IScheduleItem) {
			return {
				'drag-item': this.dragItemId && this.dragItemId === item.id,
				'status-started': [
					ScheduleStatus.Started,
					ScheduleStatus.ImagesAvailable,
					ScheduleStatus.ImagesPendingReview,
				].includes(item.scheduleStatusId),
				'status-imaging-complete': [ScheduleStatus.ImagingComplete, ScheduleStatus.ReportInProgress].includes(
					item.scheduleStatusId
				),
				'status-report-pending-approval': [
					ScheduleStatus.ReportPendingApproval,
					ScheduleStatus.ReportPendingRounds,
				].includes(item.scheduleStatusId),
				'status-report-complete': item.scheduleStatusId === ScheduleStatus.ReportComplete,
			}
		},
		getStatusIcon(item: IScheduleItem) {
			switch (item.scheduleStatusId) {
				case ScheduleStatus.Started:
					return 'clock-o'
				case ScheduleStatus.ImagesAvailable:
					return 'clock-o'
				case ScheduleStatus.ImagesPendingReview:
					return 'clock-o'
				case ScheduleStatus.ImagingComplete:
					return 'check'
				case ScheduleStatus.ReportInProgress:
					return 'check'
				case ScheduleStatus.ReportPendingApproval:
					return 'clock-o'
				case ScheduleStatus.ReportPendingRounds:
					return 'clock-o'
				case ScheduleStatus.ReportComplete:
					return 'check'
				default:
					return null
			}
		},
	},
}
