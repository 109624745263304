


































































































































































import { mapState, mapActions } from 'vuex'
import store from '@store'
import { consultations } from '@services/consultationService'
import { lockService } from '@services/lockService'
import Tabs from '@components/Tabs.vue'
import ModalDrawer from '@components/ModalDrawer.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import AstToolbar from '@components/Toolbar.vue'
import { eventBus } from '@services/eventBus'
import { showInfo, showAlert } from '@dialogs/MessageDlg.vue'
import { Dbounce } from '@utils/dbounce'
import { openReportPdf } from '@dialogs/ReportPdf.vue'
import SaveReport from '@reporting/components/SaveReport.vue'
import { openPromptDlg } from '@dialogs/TextPromptDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { openScheduleItemDlg } from '@/schedule/dialogs/ScheduleItemDlg.vue'
import { userData } from '@services/userData'
import { openResidents } from '@dialogs/Residents.vue'
import reportService from '@services/reportService'
import { ReportDetail } from '@/reporting/classes'
import { highlightKeywords } from '@services/hilitor'
import { openEmailStudyDlg } from '@/dialogs/emailStudyDlg.vue'
import api from '@services/api'
import { EmailItem } from '@/components/EmailStudyForm.vue'
import { addNotification } from '@services/notificationService'
import WysiwygEditorTiny from "@components/WysiwygEditorTiny.vue";
import GoogleAnalytics from '@services/analyticsService'

const { REPORT_COMPLETED, REFRESH_REPORT, IMAGE_CHANGE, REPORT_IMAGE_COMMENT, RESIZE } = eventBus.type
let nextReport: ReportDetail = null

export default {
	name: 'TeleconsultationDetail',
	components: {
		WysiwygEditorTiny,
		ModalDrawer,
		AstToolbar,
		ToolbarButton,
		SaveReport,
		Tabs,
	},
	filters: {
		url(value) {
			if (value.substring(0, 4).toLowerCase() !== 'http') {
				value = 'http://' + value
			}
			return value
		},
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		keywords: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			report: null,
			isSaving: false,
			isActionsOpen: false,
			isChangingClaim: false,
			isPageLoading: false,
			completeRetryTimeout: undefined,
			pollingInterval: undefined,
			// multiple resident workflow
			isResidentReviewExpanded: true,
			isSavingResidentResponseReview: false,
			residentResponseReview: null,
			residentResponseReviewHasChanged: false,
			residentResponseReviewTextValue: '',
			selectedResidentResponse: null,
			useInlineResidentReview: false,
			reportTabs: null,
			wysiwygMaxHeight: '60vh',
		}
	},
	computed: {
		...mapState({
			claims: (state: any) => state.auth.claims,
			permissions: (state: any) => state.static.permissions,
			groupMembers: (state: any) => state.consultant.groupMembers,
		}),
		consultantReportId() {
			return this.report?.consultantReportId
		},
		residentResponses() {
			return this.report?.residentResponses || []
		},
		hasResidentResponseReview() {
			return (
				this.residentResponseReviewTextValue ||
				(this.selectedResidentResponse.comment && this.selectedResidentResponse.comment.message)
			)
		},
		radiologistFeedback() {
			if (!this.claims.isConsultantResident) return null
			const residentResponse = this.report.residentResponses.find(r => r.consultantId === this.claims.userId)
			if (!residentResponse || !residentResponse.comment || !residentResponse.comment.message) return null
			const radiologist = this.groupMembers.find(c => c.id === residentResponse.comment.consultantId)
			return {
				...residentResponse.comment,
				radiologistName: radiologist ? radiologist.name : 'radiologist',
			}
		},
		priorityInfo() {
			return this.report?.priorityInfo
		},
		isRespondingAsResident() {
			return this.claims.isConsultantResident && this.residentResponses.length && !this.report.isComplete
		},
		isResidentResponseSubmitted() {
			if (!this.isRespondingAsResident) return false
			const rr = this.residentResponses.find(r => r.consultantId === this.claims.userId)
			return rr.status > 0
		},
		isClaimable() {
			if (this.selectedResidentResponse) return false // resident responses do not require claiming/unclaiming
			if (this.isRespondingAsResident) return false
			if (!this.claims.isConsultantUser) return false // only consultants can claim
			if (this.report.isComplete) return false // only incomplete reports can be claimed/unclaimed
			if (this.report.isLocked) return false // only unclaimed reports can be claimed
			if (!this.report.groupConsultantId) return false // claiming/unclaiming only applies to reports sent to a group
			if (this.claims.isConsultantTranscriptionist) return false // transcriptionists cannot claim
			return true
		},
		isUnclaimable() {
			if (this.selectedResidentResponse) return false // resident responses do not require claiming/unclaiming
			if (this.isRespondingAsResident) return false
			if (!this.report.groupConsultantId) return false // claiming/unclaiming only applies to reports sent to a group
			if (this.report.isComplete) return false // only incomplete reports can be claimed/unclaimed
			// user must either be the assigned consultant group member OR have the UserAdministration permission
			const isGroupMemberAndOwner = this.claims.isConsultantUser && this.claims.isConsultantMember && this.isOwner
			if (!isGroupMemberAndOwner && !userData.permissions.userAdministration) return false
			return true
		},
		isCompletable() {
			if (this.selectedResidentResponse) return false
			if (this.isResidentResponseSubmitted) return false
			if (!this.report.isValid) return false
			if (this.isReadOnly) return false
			if (this.report.isComplete) return false
			if (!this.isOwner) return false
			if (this.claims.isConsultantTranscriptionist) return false
			return true
		},
		hasPermissionToFinalize() {
			if (!this.claims.isConsultantUser) return false
			const canOnlyDraft = this.claims.isConsultantIntern || this.claims.isConsultantResident
			return !canOnlyDraft
		},
		isOwner() {
			if (!this.report) return false
			if (!this.claims.isConsultantUser) return false
			if (!this.report.groupConsultantId) return true
			if (this.isRespondingAsResident) return true
			return this.claims.userId === this.report.lockedByUserId
		},
		isReadOnly() {
			return !this.claims.isConsultantUser || !this.isOwner || this.report.isComplete
		},
		deleteWarning() {
			if (this.report.canDelete) return null
			if (this.report.isComplete) return 'Completed reports may not be deleted.'
			if (
				this.report.lockedByUserId &&
				this.permissions.deleteStudy &&
				(this.claims.isConsultantUser || this.permissions.serverAdministration)
			)
				return 'This report must be unlocked before it can be deleted.'
			return null
		},
		actionsPanel() {
			return this.panels.find(panel => panel.name === 'actionsPanel')
		},
		statusClass() {
			if (this.report.isComplete || this.isResidentResponseSubmitted) return 'is-success'
			if (this.report.isLocked) return 'is-danger'
			if (this.report.groupConsultantId) return 'is-info'
			return 'is-warning'
		},
		statusIcon() {
			if (this.report.isComplete || this.isResidentResponseSubmitted) return 'check-circle'
			if (this.report.isLocked) return 'lock'
			return ''
		},
		statusHeading() {
			let report = this.report

			if (report.isComplete) {
				const completedDate = this.$options.filters.localizeDate(report.completedDateTime)
				if (report.consultant.type !== 'ImageOnly') return `Completed by ${report.completedBy} on ${completedDate}`
				if (report.consultant.type === 'ImageOnly') return `Submitted to ${report.completedBy} on ${completedDate}`
			}

			if (this.isResidentResponseSubmitted) return `Sent for Approval`

			if (report.isLocked) {
				const lockedDate = this.$options.filters.localizeDate(report.lockedDateTime)
				return `Locked by ${this.isOwner ? 'you' : report.lockedBy} on ${lockedDate}`
			}

			if (report.groupConsultantId && !this.isRespondingAsResident) return `Unclaimed`
			return `Incomplete`
		},
		template() {
			return this.report?.activeTemplate
		},
		completedResidentResponseCount() {
			if (!this.residentResponses.length) return 0
			return this.residentResponses.filter(r => r.status > 0).length
		},
		residentsButtonBadge() {
			if (!this.residentResponses.length || this.report.isComplete) return ''
			return `${this.completedResidentResponseCount} / ${this.residentResponses.length}`
		},
		canCreateScheduleItem() {
			return userData.canCreateScheduleItem
		},
	},
	watch: {
		id() {
			this.loadReport()
		},
	},
	async beforeRouteEnter(to, from, next) {
		try {
			nextReport = await getReportDetail(to.params.id)
			// Cancel navigation if images are not ready or error
			if (!nextReport) return next(from.name ? false : '/teleconsultations')
			next()
		} catch (err) {
			next(from.name ? false : '/teleconsultations')
		}
	},
	async beforeRouteUpdate(to, from, next) {
		const isLoadingAnotherReport = to.params.id !== from.params.id
		if (!isLoadingAnotherReport) return next()
		this.isPageLoading = true
		await this.saveReport()
		nextReport = await getReportDetail(to.params.id)
		this.isPageLoading = false
		// Cancel navigation if images are not ready or error
		if (!nextReport) return next(from.name ? false : '/teleconsultations')
		next()
	},
	async beforeRouteLeave(to, from, next) {
		if (this.forceNavigation || !this.claims.isConsultantUser) return next()
		await this.saveReport()
		next()
	},
	async created() {
		eventBus.on(REFRESH_REPORT, this.refreshReport)
		eventBus.on(IMAGE_CHANGE, this.refreshReport)
		eventBus.on(REPORT_IMAGE_COMMENT, this.onCommentUpdated)
		eventBus.on(RESIZE, this.resizeResidentReview)
		window.name = 'report-detail'

		const ONE_MINUTE = 60000
		this.pollingInterval = setInterval(this.refreshReport, ONE_MINUTE)
		this.dbounce = new Dbounce(3000, this.saveReport.bind(this))
		this.loadReport()
	},
	beforeDestroy() {
		eventBus.off(REFRESH_REPORT, this.refreshReport)
		eventBus.off(IMAGE_CHANGE, this.refreshReport)
		eventBus.off(REPORT_IMAGE_COMMENT, this.onCommentUpdated)
		eventBus.on(RESIZE, this.resizeResidentReview)
		clearInterval(this.pollingInterval)
		clearTimeout(this.completeRetryTimeout)
		this.dbounce.clear()
	},
	methods: {
		...mapActions(['openStudy']),
		newExam() {
			openScheduleItemDlg(null, null, this.report.patient)
		},
		redirectToList() {
			// If Splitview:
			if (this.$router.currentRoute.name === 'teleconsultations') {
				this.loadReport()
			} else {
				this.forceNavigation = true
				this.$router.push({ name: 'teleconsultations' })
			}
		},
		resizeResidentReview() {
			if (!this.$refs.listScroll) return
			if (this.$refs.listScroll.clientWidth <= 800 || this.$refs.listScroll.clientHeight <= 250) {
				this.useInlineResidentReview = true
				this.wysiwygMaxHeight = '60vh'
			} else {
				this.useInlineResidentReview = false
				this.wysiwygMaxHeight = this.$refs.listScroll.clientHeight - 200 + 'px'
			}
		},
		// Listen for events from other windows spawned from the main Omni window.
		onCommentUpdated(reportId) {
			if (reportId !== this.report.reportId) return
			this.refreshReport()
		},
		async refreshReport() {
			await this.saveReport()
			const report = await getReportDetail(this.consultantReportId)
			if (!report) return this.redirectToList()
			this.report.refresh(report)
		},
		async loadReport(reportId: string = null) {
			if (!reportId) reportId = this.id
			if (this.report) await this.saveReport()
			if (this.$el) this.$el.querySelector('.list-scroll').scrollTo(0, 0)

			this.isPageLoading = true
			this.report = nextReport || (await getReportDetail(reportId))
			nextReport = null
			this.isPageLoading = false
			if (!this.report) return

			this.template.onChangeCB = this.dbounce.set.bind(this.dbounce)
			this.template.pendingChanges = false
			if (this.template.root.readOnly) {
				highlightKeywords(this.keywords, '#rootReportLayout')
			}
			this.refreshReportTabs()
			this.$nextTick(this.resizeResidentReview)
		},
		refreshReportTabs() {
			this.reportTabs = this.report.relatedReports
				.map(report => ({
					id: report.id,
					isOriginal: report.id === this.id,
					report,
					onSelect: () => this.loadReport(report.id),
				}))
				.sort((a, b) => b.isOriginal - a.isOriginal)
		},
		async claim() {
			this.isChangingClaim = true
			await lockService.lock(this.consultantReportId)
			this.isChangingClaim = false
		},
		async releaseClaim() {
			this.isChangingClaim = true
			await lockService.unlock(this.consultantReportId, false)
			this.isChangingClaim = false
		},
		async openResidents() {
			if (this.residentResponses.length) {
				const result = await openResidents(this.residentResponses, this.report.isComplete, this.isOwner)
				if (
					!result ||
					(result.responseToView &&
						this.selectedResidentResponse &&
						result.responseToView.id === this.selectedResidentResponse.id)
				)
					return
				if (result && (await this.closeResidentResponse())) {
					let report: ReportDetail = this.report
					if (result.responseToView) {
						this.selectedResidentResponse = result.responseToView
						this.isResidentReviewExpanded = true
						report.applyResidentResponse(result.responseToView)
						this.residentResponseReview = result.responseToView.comment && result.responseToView.comment.message
						report.setCanRespond()
					} else if (result.responseToApply) {
						report.mergeResidentResponse(result.responseToApply)
					}
				}
			}
		},
		toggleResidentResponse() {
			if (this.selectedResidentResponse) {
				this.closeResidentResponse()
			} else {
				const residentResponse = this.residentResponses.find(r => r.consultantId === this.claims.userId)
				if (!residentResponse) return
				this.selectedResidentResponse = residentResponse
				this.report.applyResidentResponse(residentResponse)
			}
		},
		updateTextValue(htmlValue, textValue) {
			if (textValue != null) {
				this.residentResponseReviewTextValue = textValue.trim()
				if (
					textValue.trim() ||
					(this.selectedResidentResponse.comment && this.selectedResidentResponse.comment.message)
				)
					this.residentResponseReviewHasChanged = true
			}
		},
		async reviewResidentResponse(complete) {
			if (this.isSavingResidentResponseReview) return
			try {
				this.isSavingResidentResponseReview = true
				await reportService.reviewResidentResponse(
					this.selectedResidentResponse.id,
					this.residentResponseReview,
					complete
				)
				this.residentResponseReviewHasChanged = false
				if (complete) {
					this.closeResidentResponse()
					this.$store.dispatch('addNotification', {
						message: 'Your feedback has been submitted.',
						notificationType: 'success',
					})
				}
			} finally {
				this.isSavingResidentResponseReview = false
			}
		},
		async closeResidentResponse() {
			if (!this.selectedResidentResponse) return true
			const isReviewPending =
				!this.isRespondingAsResident &&
				this.residentResponseReviewHasChanged &&
				this.selectedResidentResponse.status < 2
			if (isReviewPending) {
				if (!(await showConfirm('You have not submitted your feedback.  Are you sure you want to leave?'))) return false
			}
			try {
				this.isPageLoading = true
				this.report = await getReportDetail(this.consultantReportId)
				this.selectedResidentResponse = null
				this.residentResponseReview = null
				this.residentResponseReviewHasChanged = false
				this.residentResponseReviewTextValue = ''
				this.template.onChangeCB = this.dbounce.set.bind(this.dbounce)
				return true
			} finally {
				this.isPageLoading = false
			}
		},
		async deleteReport() {
			this.isSaving = true
			try {
				const reason = await openPromptDlg({
					buttonLabel: 'Delete',
					title: 'Delete Report',
					prompt: 'Please enter a reason for deleting this report.',
					type: 'textarea',
					requireInput: true,
				})
				if (reason) {
					await consultations.deleteReport(this.consultantReportId, reason)
					this.$store.dispatch('addNotification', {
						notificationType: 'success',
						message: 'Report deleted!',
					})
					this.redirectToList()
				}
			} finally {
				this.isSaving = false
			}
		},
		async saveReport() {
			if (this.isReadOnly || !this.template.pendingChanges) return
			try {
				this.isSaving = true
				let data = this.report.getSaveResponseForm()
				await consultations.saveConsultantResponse(data)
				this.template.pendingChanges = false
			} finally {
				eventBus.post(eventBus.type.REPORT_SAVE)
				this.isSaving = false
			}
		},
		// Wait for any saving operation to complete, and then completeReport
		tryCompleteReport() {
			clearTimeout(this.completeRetryTimeout)
			this.isPageLoading = true
			if (!this.isCompletable) {
				this.isPageLoading = false
				return
			}
			// if currently saving a response/comment, retry in one second
			if (this.isSaving) {
				this.completeRetryTimeout = setTimeout(this.tryCompleteReport, 1000)
				return
			}
			this.completeReport()
		},
		async completeReport() {
			this.isSaving = true

			try {
				let data = this.report.getSaveResponseForm(true)
				await consultations.saveConsultantResponse(data)
				lockService.fetchInfo()

				this.$store.dispatch('addNotification', {
					notificationType: 'success',
					message: this.hasPermissionToFinalize ? 'Report completed!' : 'Response submitted for approval!',
				})
				eventBus.broadcast(REPORT_COMPLETED, this.report.reportId)
				this.redirectToList()
			} catch (err) {
				console.warn(err)
			} finally {
				// We always need to setPageLoading to false, because in splitview modes, completing the report won't navigate because it's already on that route.
				this.isPageLoading = false
				this.isSaving = false
			}
		},
		async openEmailReportDlg() {
			let report: ReportDetail = this.report
			let studies: IStudy[] = await api.viewer.getStudy({ ids: report.studyIds }, false).then(r => r.studies)

			let images: EmailItem[] = []
			studies.forEach(study => {
				study.imageData.series.forEach(s => {
					images.push(new EmailItem(s, s.images[0], !s.isFakeSeries))
				})
			})

			images = images.filter(i => report.images.some(x => x.id === i.imageId))
			openEmailStudyDlg(studies, images, [report.consultantReportId]).catch(e => { })
		},
		openViewer() {
			try {
				GoogleAnalytics.sendGAEvent('Teleconsultation', {
					event_category: 'Launch',
					event_label: 'Teleconsultation Viewer'
				})
			}
			catch (err) {
				console.log(err)
			}
			this.openStudy({
				clinicCode: this.claims.activeClinicCode,
				reportId: this.report.reportId,
				modality: this.report.study.modality,
			})
		},
		downloadReport(imageType) {
			this.$api.file.downloadReport({
				downloadType: imageType === 'download-jpeg' ? 0 : 1,
				clinicCode: this.claims.activeClinicCode,
				id: this.report.reportId,
			})
		},
		openReportPdf() {
			const { reportId, consultantReportId, isComplete } = this.report
			openReportPdf(
				reportId,
				consultantReportId,
				isComplete,
				this.report.appliedResidentResponseId,
				this.report.forceConsultantHeader
			)
		},
		viewPriorityEscalationDetail() {
			showInfo('Reason for escalation: ' + this.priorityInfo.priorityChangeComment)
		},
		async confirmPriorityChange() {
			const reason = await openPromptDlg({
				buttonLabel: 'OK',
				title: 'Change Report Priority',
				prompt:
					'You are about to change the priority of this report from Standard to STAT. ' +
					'This action cannot be undone. Changing the priority of the report may trigger the report ' +
					'to be made available to all radiologists in the group as well as external teleconsultation ' +
					'providers. Are you sure you want to do this? Please provide a reason for the priority change.',
				type: 'textarea',
				requireInput: true,
			})
			if (reason) {
				await consultations.escalatePriority(this.report.consultantReportId, reason)
				this.$store.dispatch('addNotification', {
					notificationType: 'success',
					message: 'Report priority changed to STAT',
				})
				this.isActionsOpen = false
				this.refreshReport()
			}
		},
		async confirmRecall(clinicCode) {
			var provider = this.formatClinicCode(clinicCode)
			const warning = `Would you like to recall the report from ${provider}?  It will be claimed by you after the recall is complete.`
			if (await showConfirm(warning)) {
				if (clinicCode === 'PETRAYS') {
					let recall = await consultations.recallPetRaysReport(this.report.consultantReportId)
					this.isActionsOpen = false
					if (!recall.recalled) {
						showAlert(`Unable to recall the report from ${provider}.  The error is: ${recall.errors}`)
					} else {
						store.dispatch('addNotification', {
							message: `Report recalled from ${provider} successfully.`,
							notificationType: 'success',
						})
						this.refreshReport()
					}
				}
				else {
					let consultation: IConsultationForRecall = { consultantReportId: this.report.consultantReportId, userId: this.claims.userId, clinicCode: this.claims.activeClinicCode }
					let recall = await consultations.recallReport(consultation)
					this.isActionsOpen = false
					if (!recall.Success) {
						showAlert(`Unable to recall the report from ${provider}.  The error is: ${recall.ErrorMessage}`)
					} else {
						store.dispatch('addNotification', {
							message: `Report recalled from ${provider} successfully.`,
							notificationType: 'success',
						})
						this.refreshReport()
					}
				}
			}
		},
		formatClinicCode(clinicCode) {
			if (clinicCode === "PETRAYS") { return "PetRays" }
			else if (clinicCode === "ANTECH") { return "Antech" }
			else if (clinicCode === "VTSCHOAPIINTEGRATION") { return "Vets Choice" }
		}
	},
}
async function getReportDetail(id) {
	if (!id) return
	try {
		const detail = await consultations.getConsultantReport(id)
		if (!detail.areImagesAvailable) {
			store.dispatch('addNotification', {
				notificationType: 'warn',
				message: 'Report images have not yet finished archiving. Please try again in a few minutes.',
			})
			return
		}
		return detail
	} catch (err) {
		showAlert(err.message)
	}
}
